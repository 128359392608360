'use client'

import Image from 'next/image'
import { useState, useEffect } from 'react'
import Script from 'next/script'

interface ImageWithMetadataProps {
  src: string
  alt: string
  width: number
  height: number
  className?: string
}

export function ImageWithMetadata({ src, alt, width, height, className }: ImageWithMetadataProps) {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <div className={`relative ${className || ''}`} style={{ width, height }}>
      <Image
        src={src}
        alt={alt}
        fill
        style={{ objectFit: 'cover' }}
        onLoad={() => setIsLoaded(true)}
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        priority
        // Add structured data for SEO
        itemProp="image"
        // Add loading animation
        className={`transition-opacity duration-300 ${isLoaded ? 'opacity-100' : 'opacity-0'}`}
      />
    </div>
  )
}

interface PartnerLogoProps {
  name: string;
  src: string;
  metadata?: {
    alt?: string;
    description?: string;
    keywords?: string[];
  };
}

export function PartnerLogo({ name, src, metadata }: PartnerLogoProps) {
  const [isLoaded, setIsLoaded] = useState(false)
  
  return (
    <div className="h-16 w-full relative">
      <Image
        src={src}
        alt={metadata?.alt || `${name} Logo - E-Taxi Partner`}
        fill
        style={{ objectFit: 'contain' }}
        sizes="(max-width: 768px) 33vw, 150px"
         // Add structured data for SEO
         itemProp="logo"
         loading="lazy" // Defer loading for offscreen images
         onLoad={() => setIsLoaded(true)}
         className={`object-contain transition-opacity duration-300 ${isLoaded ? 'opacity-100' : 'opacity-0'}`}
      />
      {metadata && (
        <Script id={`partner-metadata-${name.toLowerCase().replace(/\s+/g, '-')}`} type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'ImageObject',
            contentUrl: src,
            name: metadata.alt || `${name} Logo`,
            description: metadata.description || `${name} - E-Taxi Tunisia Partner`,
            keywords: (metadata.keywords || []).join(', ')
          })}
        </Script>
      )}
    </div>
  )
}
