'use client'

import { useTranslations } from 'next-intl'

interface Feature {
  id: string
  name: string
  description: string
  icon: string
}

export function Features() {
  const t = useTranslations('home')

  const features: Feature[] = [
    {
      id: 'reliability',
      name: t('features.features.reliability.title'),
      description: t('features.features.reliability.description'),
      icon: '⚡',
    },
    {
      id: 'affordable',
      name: t('features.features.affordable.title'),
      description: t('features.features.affordable.description'),
      icon: '💰',
    },
    {
      id: 'safety',
      name: t('features.features.safety.title'),
      description: t('features.features.safety.description'),
      icon: '🛡️',
    },
    {
      id: 'professional',
      name: t('features.features.professional.title'),
      description: t('features.features.professional.description'),
      icon: '👔',
    },
  ]

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            {t('features.subtitle')}
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {t('features.title')}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('features.description')}
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            {features.map((feature) => (
              <div key={feature.id} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <span className="text-2xl">{feature.icon}</span>
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
