import(/* webpackMode: "eager" */ "/home/deploy/etaxi-tn-next/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/deploy/etaxi-tn-next/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/deploy/etaxi-tn-next/src/components/home/Features.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/home/deploy/etaxi-tn-next/src/components/home/Hero.tsx");
;
import(/* webpackMode: "eager" */ "/home/deploy/etaxi-tn-next/src/components/home/Services.tsx");
;
import(/* webpackMode: "eager" */ "/home/deploy/etaxi-tn-next/src/components/home/Testimonials.tsx");
